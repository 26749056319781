module services {
    export module tariff {
        export class aeoTypeService implements interfaces.tariff.IAeoTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all TariffCountry for a dropdown/autocomplete list
            getForDropDownByCountry(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "AeoType/GetForDropDownByCountry", {
                    countryId: countryId
                });
            }
        }
    }
    angular.module("app").service("aeoTypeService", services.tariff.aeoTypeService);
}